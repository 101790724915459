import React, { useContext } from "react";

import { UserContext } from "../context/UserContext";
import "./Header.css";
import bk from "../images/bk512x512.svg";
import gitlab from "../images/gitlab-logo-1-color-white-rgb.svg";

const Header = () => {
  const [token, setToken] = useContext(UserContext);

  const handleLogout = () => {
    setToken(null);
  };

  return (
    <div >
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-start pl-5">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={bk} alt="bk logo" />
              <b>Todo App</b>
            </a>
          </div>
        </div>

        <div className="navbar-end pr-5 is-hidden-mobile is-hidden-tablet-only">
          <div className="navbar-brand">
            <a
              className="navbar-item"
              href="https://gitlab.com/benjamin.komjathy/diplomamunka-frontend"
              target="_blank"
              rel="noreferrer"
            >
              <img src={gitlab} alt="Gitlab-Repo" width="112" height="28" />
            </a>
          </div>
        </div>
      </nav>

      <div className="has-text-centered m-4">
        <h1 className="title">Todo App</h1>
        {token && (
          <button className="button" onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;

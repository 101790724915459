import React from "react";
import { render } from 'react-dom';
import "bulma/css/bulma.min.css"
import App from './App'

import { UserProvider } from "./context/UserContext";


const rootElement = document.getElementById("root")
render(<UserProvider>
  <App />
  </UserProvider>, rootElement)
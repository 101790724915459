import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TodoModal = ({ active, handleModal, token, id, setErrorMessage }) => {
  const [todo, setTodo] = useState("");
  const [due, setDue] = useState("");
  const [date, setDate] = useState(new Date());
  

  useEffect(() => {
    const fetchTodo = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/todo/${id}`, requestOptions);

      if (!response.ok) {
        setErrorMessage("Could not get the todo");
      } else {
        const data = await response.json();
        const date = new Date(data.due*1000);
        setTodo(data.todo);
        setDue(data.due);
        setDate(date);
        
      }
    };

    if (id) {
        fetchTodo();
    }
  }, [id, token]);

  const cleanFormData = () => {
    setTodo("");
    setDue("");
    setDate(new Date());
    
  };

  const handleCreateTodo = async (e) => {
    e.preventDefault();
    const unix=new Date(date).getTime() / 1000
    const sync_at=new Date().getTime() / 1000
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        "todo": todo,
        "due": unix,
        "sync_at":sync_at
        
      }),
    };
    const response = await fetch(process.env.REACT_APP_BACKEND_URL+"/todo/", requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong when creating todo");
    } else {
      cleanFormData();
      handleModal();
    }
  };

  const handleUpdateTodo = async (e) => {
    e.preventDefault();
    const unix=new Date(date).getTime() / 1000
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        "todo": todo,
        "due": unix
      }),
    };
    const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/todo/${id}`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong when updating todo");
    } else {
      cleanFormData();
      handleModal();
    }
  };

  return (
    <div className={`modal ${active && "is-active"}`}>
      <div className="modal-background" onClick={handleModal}></div>
      <div className="modal-card">
        <header className="modal-card-head has-background-primary-light">
          <h1 className="modal-card-title">
            {id ? "Update Todo" : "Create Todo"}
          </h1>
        </header>
        <section className="modal-card-body">
          <form>
            <div className="field">
              <label className="label">Due Date</label>
              <div className="control">
                
                <DatePicker dateFormat="yyyy/MM/dd" selected={date} onChange={(date,e) => setDate(date,e.target.value)} inline />
              </div>
            </div>
            <div className="field">
              <label className="label">Todo</label>
              <div className="control">
                <input
                  type="text"
                  placeholder="Enter Todo"
                  value={todo}
                  onChange={(e) => setTodo(e.target.value)}
                  className="input"
                  required
                />
              </div>
            </div>
            
           
           
          </form>
        </section>
        <footer className="modal-card-foot has-background-primary-light">
          {id ? (
            <button className="button is-info" onClick={handleUpdateTodo}>
              Update
            </button>
          ) : (
            <button className="button is-primary" onClick={handleCreateTodo}>
              Create
            </button>
          )}
          <button className="button" onClick={handleModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default TodoModal;

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import ErrorMessage from "./ErrorMessage";
import TodoModal from "./TodoModal";
import UserModal from "./UserModal";
import { UserContext } from "../context/UserContext";

const Table = () => {
    const [token] = useContext(UserContext);
    const [todos, setTodos] = useState(null)
    const [errorMessage, setErrorMessage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [activeUserModal, setActiveUserModal] = useState(false);
    const [id, setId] = useState(null);

  const handleUpdate = async (id) => {
    setId(id);
    setActiveModal(true);
  };

  const handleDelete = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/todo/${id}`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Failed to delete todo");
    }

    fetchTodos();
  };

  const fetchTodos = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },};
    

    const response = await fetch(process.env.REACT_APP_BACKEND_URL+"/todo/",requestOptions)
    if (!response.ok) {
      setErrorMessage("Something went wrong. Couldn't load the todos");
    } else {
      const todos = await response.json();
      setTodos(todos);
      setLoaded(true);
    }

    
  }
  useEffect(() => {
    fetchTodos()
  }, []);

  const handleModal = () => {
    setActiveModal(!activeModal);
    fetchTodos();
    setId(null);
  };
  const handleUserModal = () => {
    setActiveUserModal(!activeUserModal);
    
  };
const datenow=new Date()
const weekago=new Date()-604800000
  return (
    <>
      <TodoModal
        active={activeModal}
        handleModal={handleModal}
        token={token}
        id={id}
        setErrorMessage={setErrorMessage}
      />
      <UserModal
        active={activeUserModal}
        handleUserModal={handleUserModal}
        token={token}
        setErrorMessage={setErrorMessage}
      />
      <button
        className="button is-fullwidth mb-5 is-primary"
        onClick={() => setActiveModal(true)}
      >
        Create Todo
      </button>
      <button
        className="button is-fullwidth mb-5 is-primary"
        onClick={() => setActiveUserModal(true)}
      >
        Update User
      </button>
      <ErrorMessage message={errorMessage} />
      {loaded && todos ? (
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Todo</th>
              <th>Due</th>
              
              <th>Last Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {todos.map((todo) => (
              <tr key={todo.id}>
                <td>{todo.todo}</td>
                <td className={ moment(todo.due*1000)<datenow ?"is-danger":"" }>{moment(todo.due*1000).format("YYYY/MM/DD")}</td>
                <td>{moment(todo.sync_at*1000).format("YYYY/MM/DD")}</td>
                <td>
                  <button
                    className="button mr-2 is-info"
                    onClick={() => handleUpdate(todo.id)}
                  >
                    Update
                  </button>
                  <button
                    className="button mr-2 is-danger"
                    onClick={() => handleDelete(todo.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default Table;

import { LayoutGroupContext } from "framer-motion";
import React, { useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";

const UserModal = ({ active, token,handleUserModal,  setErrorMessage }) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [logout,setLogout]=useState(UserContext);
    const handleLogout = () => {
      setLogout(null);
    };
   
    

    
  
    useEffect(() => {
      const fetchUser = async () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/users/me`, requestOptions);
  
        if (!response.ok) {
          setErrorMessage("Could not get the user");
        } else {
          const data = await response.json();
          setEmail(data.email);
          setName(data.name);
          
          
        }
      };
  
      
          fetchUser();
      
    }, [token]);
  
    const cleanFormData = () => {
      setName("");
      setEmail("");
      
      
    };
  
    
    
  
    const handleUpdateUser = async (e) => {
      e.preventDefault();
    
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          "email": email,
          "name": name
        }),
      };
      const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/users`, requestOptions);
      if (!response.ok) {
        setErrorMessage("Something went wrong when updating user");
      } else {
        //cleanFormData();
        handleUserModal();
        
      }
    };
    const handleDeleteUser = async (e) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(process.env.REACT_APP_BACKEND_URL+`/users`, requestOptions);
      if (!response.ok) {
        setErrorMessage("Failed to delete user");
      }
  
      handleLogout();
    };
  
    return (
      <div className={`modal ${active && "is-active"}`}>
        <div className="modal-background" onClick={handleUserModal}></div>
        <div className="modal-card">
          <header className="modal-card-head has-background-primary-light">
            <h1 className="modal-card-title">
              Update User
            </h1>
          </header>
          <section className="modal-card-body">
            <form>
            <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Name</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>
              
             
             
            </form>
          </section>
          <footer className="modal-card-foot has-background-primary-light">
          <button className="button is-info" onClick={handleUpdateUser}>
                Update
              </button>
              <button className="button is-danger" onClick={handleDeleteUser}>
                Delete
    </button>
            <button className="button" onClick={handleUserModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  };
  
  export default UserModal;
